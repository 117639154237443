<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1 h-100">
                    <span class="headline font-weight-medium">Empresas</span>
                </v-col>
                <v-col cols="2" class="pt-1 h-100" v-if="showButtonNew()">
                    <v-btn v-if="this.$hasPermision('companies.create')" 
                        dark color="#023145" 
                        width="140" 
                        class="float-right" 
                        @click="handleShowCreate">
                        Nuevo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pt-1 h-100">
                    <v-text-field
                        v-model="search_company"
                        label="Buscar"
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo>
                    </v-text-field>              
                </v-col>
                <v-col cols="6" class="pt-1 h-100">
                    <v-btn class="w-btn-search" height="40" @click="handleSearch">
                        Buscar
                    </v-btn>
                </v-col>
                <v-col cols="2" class="pt-1 h-100">
                    <v-btn 
                        v-if="this.$hasPermision('companies.filters')"
                        dark 
                        color="#023145" 
                        outlined 
                        width="140" 
                        class="float-right" 
                        @click="handleShowFilters">
                        <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
                        Ver Filtros
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="h-100">
                <v-col cols="12" class="h-100">
                    <v-card class="h-100">
                        <v-data-table
                            v-model="selectedRows"
                            :headers="headers"
                            :items="companies"
                            :items-per-page="10"
                            class="elevation-1 w-table table-crud"
                            show-select
                            height="57vh"
                            :single-select="true"
                            @item-selected="handleSelectItem"
                            @toggle-select-all="handleSelectAll"
                        >
                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">Todas las empresas</span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.name="{ item }">
                                <span class="font-weight-medium">{{ item.name }}</span>
                                <span v-if="item.active" class="activo">
                                    <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                                    activo
                                </span>
                                <span v-else class="inactivo">
                                    <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                                    inactivo
                                </span>
                                <br>
                                <v-icon v-if="item.email" class="mr-1" size="13" color="#303197">fas fa-envelope</v-icon>
                                <span class="font-italic email-company">                                    
                                    {{ item.email }}
                                </span>
                            </template>
                        </v-data-table>                        
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <div class="text-center">
            <v-bottom-sheet v-model="show_options_crud" :hide-overlay="true" persistent :retain-focus="false">
                <v-sheet class="text-center" height="85px">
                    <div class="py-3">
                        <v-btn v-if="this.$hasPermision('companies.edit')"
                            class="mt-3" 
                            dark 
                            color="blue" 
                            outlined 
                            @click="handleEdit">
                            <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
                            Editar
                        </v-btn>
                        <v-btn v-if="show_btn_active && this.$hasPermision('companies.change_status')" 
                            class="ml-4 mt-3" 
                            dark 
                            color="green" 
                            outlined 
                            @click="handleChangeStatus">
                            <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
                            Activar
                        </v-btn>
                        <v-btn v-if="!show_btn_active && this.$hasPermision('companies.change_status')"
                            class="ml-4 mt-3" 
                            dark 
                            color="amber" 
                            outlined 
                            @click="handleChangeStatus">
                            <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
                            Inactivar
                        </v-btn>
                        <v-btn v-if="this.$hasPermision('companies.destroy')"
                            class="ml-4 mt-3" 
                            dark 
                            color="red" 
                            outlined 
                            @click="handleDelete">
                            <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
                            Eliminar
                        </v-btn>                      
                    </div>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <create-update ref="createUpdate"></create-update>
        <Filters ref="filters"></Filters>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import OfficeLogin from '../../../components/auth/OfficeLogin';

import ArrayTools from '../../../helpers/ArrayTools'
import CreateUpdate from '../../../components/companies/CreateUpdate'
import Filters from '../../../components/companies/Filters'

export default {
    name: 'Companies',
    components: {
        CreateUpdate,
        Filters,
        OfficeLogin
    },
    data() {
        return {
            search_company: null,
            drawer: false,
            show_options_crud: false,
            show_btn_active: false,
            selectedRows: [],
            itemsBreadcrumbs: [
                {
                    text: 'Configuración',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'RAZON SOCIAL', value: 'name' },
                { text: 'RUC', value: 'ruc' },                
                { text: 'NOMBRE COMERCIAL', value: 'trade_name' },
                { text: 'TELEFONO', value: 'phone', width: 110 },
                { text: 'DIRECCIÓN', value: 'address' },                
            ],
        }
    },
    computed: {
        ...mapState('authentication', [
            'wost_setting'
        ]),
        ...mapState('companies', [
            'companies'
        ])
    },
    methods: {
        ...mapActions('companies', [
            'getCompanies',
            'change_status',
            'delete'
        ]),
        showButtonNew () {
            let show = true;
            if (this.companies.length >= this.wost_setting.number_companies) {
                show = false;
            }

            return show;
        },
        showOptionCrud() {
            this.show_options_crud = this.selectedRows.length > 0
            if (this.show_options_crud) {
                this.show_btn_active = !this.selectedRows[0].active
            }
        },
        handleSelectItem(selected) {
            if (selected.value) {
                this.selectedRows.push(selected.item)
            } else {
                let index_found = ArrayTools.getIndexById(this.selectedRows, selected.item.id)
                if (index_found > -1) {
                    this.selectedRows.splice(index_found, 1)
                }
            }                     
            this.showOptionCrud()
        },
        handleSelectAll(selected){
            if (selected.value) {
                this.selectedRows = selected.items
            } else {
                this.selectedRows = []
            }
            this.showOptionCrud()
        },
        handleShowCreate() {
            this.selectedRows = []
            this.showOptionCrud()
            this.$refs.createUpdate.showForm('Nueva Empresa', 'create')
        },
        handleEdit() {
            let company = this.selectedRows[0]
            this.selectedRows = []
            this.showOptionCrud()
            this.$refs.createUpdate.showForm('Modificar datos de empresa', 'update', company)
        },
        handleShowFilters() {
            this.$refs.filters.showForm('Filtrar datos de empresas')
        },
        async handleChangeStatus() {
            let company = this.selectedRows[0]
            this.selectedRows = []
            this.showOptionCrud()
            await this.change_status(company)
        },
        async handleSearch() {
            this.selectedRows = []
            this.showOptionCrud()
            let filters = {
                name: this.search_company
            }
            await this.getCompanies(filters)
        },
        async getAllCompanies() {
            await this.getCompanies()
        },
        async handleDelete() {
            let company = this.selectedRows[0]
            await this.delete(company)
            this.selectedRows = []
            this.showOptionCrud()
        }
    },
    created() {
        this.getAllCompanies();
    }
}
</script>

<style scoped>
</style>