<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">                
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            ref="ruc"
                            v-model="companyForm.ruc" 
                            label="Ruc" 
                            outlined
                            :rules="[() => !!companyForm.ruc || 'Es necesario ingresar ruc']"
                            hide-details="auto"
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="name" 
                            v-model="companyForm.name" 
                            label="Razón Social" 
                            outlined 
                            :rules="[() => !!companyForm.name || 'Es necesario ingresar razón social']"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="companyForm.trade_name" 
                            label="Nombre Comercial" 
                            outlined 
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="companyForm.email" 
                            label="Correo electrónico" 
                            outlined 
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="companyForm.phone" 
                            label="Teléfono" 
                            outlined 
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            label="Dirección"
                            v-model="companyForm.address"
                            >
                        </v-textarea>
                    </v-col>
                </v-row>
            </template>                    
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    name: 'CreateUpdate',
    data() {
        return {
            drawer: false,
            titleForm: null,
            action: null,
            companyForm: {
                id: null,
                ruc: null,
                name: null,
                trade_name: null,
                email: null,
                address: null,
                phone: null
            }
        }
    },
    methods: {
        ...mapActions('companies', [
            'store',
            'update'
        ]),
        async showForm(title, action, company = null) {
            await this.clearData();
            this.titleForm = title            
            this.action = action
            if (company) {
                this.setCompany(company);
            }
            this.drawer = !this.drawer
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        clearData() {
            this.titleForm = null
            this.action = null
            this.companyForm.id = null
            this.companyForm.ruc = null
            this.companyForm.name = null
            this.companyForm.trade_name = null
            this.companyForm.email = null
            this.companyForm.address = null
            this.companyForm.phone = null
            this.clearErrors();
        },
        clearErrors() {
            Object.keys(this.companyForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        setCompany(company) {
            this.companyForm.id = company.id
            this.companyForm.ruc = company.ruc
            this.companyForm.name = company.name
            this.companyForm.trade_name = company.trade_name
            this.companyForm.email = company.email
            this.companyForm.address = company.address
            this.companyForm.phone = company.phone
        },
        async handleStore() {
            if (!this.existErrors()) {
                await this.store(this.companyForm);
                this.closeForm();
            }       
        },
        async handleUpdate() {
            if (!this.existErrors()) {
                await this.update(this.companyForm);
                this.closeForm();
            }            
        },
        handleCancel() {
            this.closeForm()
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.companyForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.companyForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        }
    }
}
</script>

<style scoped>

</style>